@import "../abstracts/mixins.scss";
.flexrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header {
  font-size: 20px !important;
  font-weight: bolder;
}

.mainContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.legallImg {
  width: 450px;
  height: auto;
  object-fit: contain;
  margin-left: -150px;
}

.chooseAService {
  width: 100%;
  background-color: white;
  box-shadow: 4px 4px 4px 5px #e2e0e4;

  display: flex;
  justify-content: center;
  padding-top: 15px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
}

.const_width {
  @include const_width(90%);
}
.const_width2 {
  @include const_width(100%);
}
.inputContainer {
  width: 100%;
  margin-top: 20px;
  @include customFlex();
}

.a1 {
  font-size: 16px;
  color: black;
  font-weight: 400;
  text-align: left;
  margin-bottom: 10px;
}

.phoneInput {
  width: 100%;
  height: 50px;
  font-size: 20px;
  color: black;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(193, 196, 199);
  border-radius: 5px;
  padding-left: 10px;
}
.wrap-1-cs{
  
}
.wrapContainer {
  @include customFlex(100%);
}
.wrap2 {
  @include customFlex(90%, left, center, row);
}
.marginTop25 {
  margin-top: 25px;
}

.serviceItem {
  font-size: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  cursor: pointer;
}

.watermark {
  position: absolute;
  left: 0;
  bottom: 0;
}

.abstract {
  width: 300px;
  height: auto;
  object-fit: fill;
  position: fixed;
  bottom: 50px;
  right: 80px;
  opacity: 80%;
}

.logoContainer {
  position: fixed;
  top: 25px;
  left: 150px;
  width: 150px;
  height: auto;
}

.logo {
  width: 150px;
  height: auto;
  object-fit: fill;
}

.btn-section {
  display: flex !important;
  justify-content: center;
  width: 100%;
  margin: 2rem auto;
}

.btn-section Button {
  width: 90%;
}

@media only screen and (max-width: 400px) {
  .flexrow {
    flex-direction: column;
  }

  .chooseAService {
    width: 100%;
    margin-top: 200px;

    margin-top: 30px;
    box-shadow: none;
  }

  .mainContainer {
    width: 100%;
    padding-top: 340px;
  }

  .legallImg {
    width: 300px;
    margin-left: 0px;
    margin-top: 0;
    height: auto;
    margin-bottom: 0px;
    object-fit: cover;
  }

  .logoContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    background-color: white;
    text-align: center;
    top: 0px;
    right: 0px;
    left: 0px;
    box-shadow: 2px 2px 2px 2px rgb(226, 226, 226);
  }

  .inputContainer {
    width: 95%;
  }
}

@media only screen and (max-width: 600px) {
  .mainContainer {
    padding-top: 0;
  }
  .chooseAServiceImg {
    display: none;
  }
  .logo {
    width: 100px;
  }
  .logoContainer {
    height: 60px;
  }
}
