.cm-g1 {
    width: 40%;

    padding: 5vh;

}

.cm-g1-1 {
    width: 30%;
}

.cm-g2 {
    height: 80svh;
    min-width: 1000px;
}

.dd-cm-1 {
    font-size: 10px;
    border-radius: 7px;
    border: none;
    font-weight: 700;
    color: #333;
    outline: none;
    padding: 1vh 1.5vh;
    margin: .5vh;
    width: 100%;
}

.dd-green {
    background-color: #EEFFE5;
}

.dd-red {
    background-color: #FEEDEE;
}

.dd-blue {
    background-color: #1E68F9;
    color: white;
}

.drop-down-cm {

    padding: 1vh;
    font-size: 10px;
    border-radius: 4px;
    border: none;
    font-weight: 700;
    background-color: #FEEDEE;
    color: #333;
    outline: none;
}

.cm-g2-g1 {
    width: 25%;
    border: 1px solid #D9D9D9;
    overflow: scroll;
    height: 100%;
}

.f1-cm {
    font-size: 14px;
}

p {
    padding: none;
    margin: none;
}

.cm-g2-g2 {
    border-top: 1px solid #D9D9D9;
    border-right: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    height: 100%;
}

.cm-g1g1 {
    min-height: 4em;
    align-items: center;
    padding: 1vh;
}

.cm-service-cont {
    min-height: 3.25em;
    width: 100%;
    border-bottom: 0.5px solid #D9D9D9
}

.cm-grid-1 {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .4vh 0;
}

.cm-grid-2 {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.typo-wrap-closed {
    background-color: #FFF0E4;
    color: #FF9472;
    border: 1px solid #FF9472;
}

.typo-wrap-active {
    color: #1E68F9;
    border: 1px solid #1E68F9;
    background-color: #F6F8FD;
}

.typo-wrap-inprogress {
    color: #FFBA88;
    border: 1px solid #FFBA88;
    background-color: #FFF0E4;
}

.typo-wrap-completed {
    color: #00C308;
    border: 1px solid #00C308;
    background-color: #EEFFEF;
}

.cm-grid-50 {
    height: 100%;

}

.cm-grid-50-1 {
    width: 50%;
    height: 100%;
    padding-inline-start: 1vh;
}

.cm-g50-v1 {
    padding-inline: .1vh;
}

.text-bg {
    font-weight: 500;
    font-size: 14px;
    background-color: #D1EFE1;
    color: #046F38;
    border-radius: 1vh;
    padding-inline: 2vh;
    padding-block: 0.5vh;
}

.cm-g50-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-inline: 1vh;
}

.p-cg-50 {
    font-size: 10px;
    font-weight: 500;
}

.grid-cm-25 {
    gap: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-inline: 1vh;
}

.gcm-25-30 {
    margin-inline: 1vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.gcm-text-blue {
    font-size: 16px;
    font-weight: 400;
    color: #1E68F9;
    text-decoration: underline;
    text-underline-offset: 4px;
}

.border-cm {
    border-bottom: 0.5px solid #D9D9D9;
    background-color: #F2F2F2;
    border-top: 0.5px solid #D9D9D9;
}

.text-gcm {
    padding-inline: 1vh;
    font-weight: 500;
}

.cm-w {
    height: 72svh;
    border-bottom: #D1EFE1;
}

.cm-btn-w-g {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.MuiButton-contained.cm-btn-11 {
    background-color: #1E68F9;
    border: none;
    padding-inline: 3vh;
    padding-block: 1vh;
    font-size: 15px;
    border-radius: 1vh;
    color: white;
    margin: 1vh;
    min-height: 2rem;
    text-transform: none;
}

.cm-btn-11-blue {
    background-color: #1E68F9;
    color: white;
    border: none;
    border-radius: 1vh;
}

.cm-btn-11-white {
    background-color: white;
    border-radius: 1vh;
    border: 1px solid #747474;

}

.cm-w11 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-block: 2vh;
    width: 90%;

}

.cm-w-12 {
    padding: 1vh;
    display: flex;
    justify-content: center;
    align-items: center;

}

.cm-w-90 {
    width: 90%;
}

.cm-w1 {
    height: 100%;
    width: 100%;
}

.cm-w2 {

    width: 40%;
    height: 100%;
    overflow: scroll;
}

.wrap-cm-1 {
    display: flex;
    justify-content: space-between;
    padding: 1vh 3vh;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.cm-w-t2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35vh;

}

.cm-w-t2-1 {
    padding: 1vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.cm-w-t-1 {
    width: 90%;
    border: 0.5px solid #D9D9D9;
    border-radius: 1vh;
}

.cgm-p1 {
    font-size: 10px;
    padding: 1vh;
}

.cgm-p2 {
    padding: 1vh;
    font-weight: 600;
}

.text-area-cm {
    border: none;
    border-bottom: 0.5px solid #D9D9D9;
}

.cgm-p-1 {
    background-color: #F9F9FB;
    margin-inline: 1vh;
    padding: 1vh;
}

.cgm-w-1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.cm-w3 {

    width: 50%;
    height: 100%;

}

.border-b {
    border-bottom: 1px solid #D9D9D9;
}

.border-r {
    border-right: 1px solid #D9D9D9;

}

.cm-w-t1 {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-inline: 1vh;
}

.search-cm {
    border: 1px solid #D9D9D9;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-inline: 2vh;
    border-radius: 1vh;
    height: 100%;
}

.cm-h1 {
    height: 7vh;
    padding: 1vh;
}

.p-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: gainsboro;
    color: red;
    border: 1px solid red;
    border-top-right-radius: 1vh;
    padding-block: 2vh;
    padding-inline: 1vh;
}

.cm-w-1 {
    width: 20%;
}

.cm-w-2 {
    width: 50%;
    font-weight: 500;
    font-size: 12px;

}

.cm-w-3 {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cm-p-w {
    width: 50%;
}

.cm-w-btn {
    background-color: white;
    color: #4B5D9B;
    border-radius: 1vh;
    border: 1px solid #D8D8D8;
    padding-block: 5px;
    font-size: 14px;
    font-weight: 500;
}

.cm-grid-60 {
    width: 60%;
    height: 100%;
    padding: 1vh;
}

.cm-grid-20 {
    width: 20%;
    height: 100%;
    padding: 1vh;

}

.cm-g-d-1 {
    padding-inline: 2vh;
    width: 30%;
}

.padding-v-3 {
    padding: .3rem 0
}

.cm-g-d-2 {
    display: flex;
    align-items: center;
    width: 30%;
}

.cm-g-d-3 {
    display: flex;
    align-items: center;
    width: 30%;
}

.cm-w-1-2 {
    width: 15%;




}

.cm-w-1-2-1 {
    width: 70%;
    padding-inline: 1vh;
    font-size: 8px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;

}

.cm-w-1-2-1 p {
    padding: 0px;
    margin: 0px;
}

.cm-g-w-1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    font-size: 12px;
    width: 100%;
    margin-block: 1vh;
}

.cm-fcc {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.arrow-bg {
    background-color: #D9D9D9;
    border-radius: 100%;
    padding-inline: 1vh;
}

.cm-grid-1-p {
    font-size: 14px;
    font-weight: 600;
    color: #000;
}

.width90 {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2vh;
}

.sidepop-ticket {
    font-size: 12px;
    border-radius: 1vh;
    padding: 1vh;

}

.sidepop-bg-blue {
    background-color: #F5FAFF;
    border: 1px solid #88B6F6;
}

.sidepop-bg-white {

    border: 1px solid #E6E6E6;
}

.MuiButton-contained.cm-create-status {
    background-color: #EDF1FE;
    border: none;
    padding-inline: 3vh;
    padding-block: .5vh;
    font-size: 14px;
    font-weight: 500;
    border-radius: 1vh;
    color: #64748B;
    min-height: 1rem;
    text-transform: none;
    border-radius: 3px;
}

.MuiButton-contained.cm-create-status:hover,
.MuiButton-contained.cm-create-sub-status:hover {
    background-color: #D5D5D5;
}

.MuiButton-contained.cm-create-sub-status {
    background-color: #EDF1FE;
    border: none;
    line-height: 1;
    font-size: 12px;
    font-weight: 500;
    color: #64748B;
    min-height: 1.5rem;
    text-transform: none;
}

.timeline {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
}

.timeline::before {
    content: '';
    position: absolute;
    top: 2rem;
    left: 2.25rem;
    bottom: 0;
    width: 4px;
    background: #1E68F9;
    z-index: -1;
}

.timeline-item {
    position: relative;
    padding-left: 1.5rem;

}

.timeline-item::before {
    content: '';
    position: absolute;
    left: .3rem;
    top: .75rem;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    background: #1E68F9;
    border: 2px solid #fff;
}


.zoom-in-box {
    margin-top: 0.3rem;
    animation: zoomIn 0.3s ease-in-out;
    border: 1px solid #ccc;
}

.cm-sample-file-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #CDCDCD;
    border-radius: 5px;
    height: 100%;
}

@keyframes zoomIn {
    from {
        transform: scale(0.95);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

.blue-round {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-left: .7rem;
}

.blue-round .tick-symbol {
    color: #fff;
    font-size: .8em;
    margin-left: .2rem;
}

.cm-approve-btn {
    font-weight: 500;
    font-size: 14px;
    background-color: #EAFFD4;
    color: #089700;
    border-radius: 5px;
    padding-inline: 2vh;
    padding-block: 0.5vh;
}

.cm-approve-btn:hover {
    background-color: #d4e1c6;
}

.cm-reject-btn {
    font-weight: 500;
    font-size: 14px;
    background-color: #FFDFDF;
    color: #D11100;
    border-radius: 5px;
    padding-inline: 2vh;
    padding-block: 0.5vh;
}

.cm-reject-btn:hover {
    background-color: #e6c0c0;
}
.ticket-card{
    width: fit-content;
    padding: .5rem;
    border: solid 1px #3E3E3E;
    border-radius: 5px;
    gap: .5rem;
    @include flex(start);
    cursor: pointer;
}

.ticket-card:hover{
    background: #fcf9f9;
}

.Open-ticket-status-btn {
    background-color: #E6F3FF;
    border-radius: 3px;
    padding: .4rem 1.5rem;
    border: none;
    color: #1E68F9;
    font-size: 14px;
    font-weight: 500;
}

.Closed-ticket-status-btn {
    background-color: #FFD3DA;
    border-radius: 3px;
    padding: .4rem 1.5rem;
    border: none;
    color: #F7425F;
    font-size: 14px;
    font-weight: 500;
}