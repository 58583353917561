.filterBtn{
    border: 1px solid #2A2A2A;
    text-decoration: none;
    background-color: white;
    border-radius: 4px;
    margin: 1vh;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.7vh;

}
.cc-calendar{
    background-color: #E5EEFF ;
    padding: 1vh;
    border-radius: 1vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.filterIcon{
 padding-inline-start: 1vh;
    
 font-size: 12px;

}

.cc-month{
    padding-inline: 2vh;
    border-radius: 1vh;
    margin:10px;

    display: flex;
    justify-content: center;
    align-items: center;
}
.cc-month-wrap{
    padding: 1vh;
}

.MuiButton-contained.cc-btn1{
    background-color: #1E68F9;
    border: none;
    font-size: 15px;
    border-radius: 1vh;
    color: white;
    height: 2rem;
    text-transform: none; 
}

@media only screen and (max-width: 550px) {
    .cc-content {
      height: 20rem;
    }
  }