@import "../abstracts/mixins.scss";

.newSer_Card_base {
  height: 140px;
  @include customFlex(320px, center, center, column);
  border: 0.5px solid #cccccc;
  border-radius: 4px;
  margin-right: 40px;
  margin-top: 24px;
}

.newser_card_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #666666;
  width: 75%;
  text-align: left;
  margin-top: 17px;
}

.newser_card_type {
  width: 75%;
  text-align: left;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  margin-top: 16px;
}

.newset_card_buttontextcontai {
  @include customFlex(75%, space-between, center, row);
  margin-top: 10px;
}

.newser_card_pricetext {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #666666;
}

.newSer_card_button {
  @include customFlex(120px, center, center, column);
  height: 30px;
  color: rgba(255, 255, 255, 1);
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  background-color: rgba(70, 125, 247, 1);
}
