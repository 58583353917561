@import "../abstracts/mixins.scss";

.marginbotm_25 {
  margin-bottom: 25px;
}

.registerBackground {
  display: flex;
  width: 200%;
  height: 150vh;
  background-color: #f7fafc;
  rotate: -19deg;
  z-index: -10;
  position: fixed;
  margin-left: -70%;
  margin-top: -75vh;
}

.registermain {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0;
}

.registermainContainer{
    width: 75%;
    border-width: 1px;
    border-color: #E2E0E4;
    flex-direction: row;
    border-style: solid;
    justify-content: space-between;
    display: flex;
    padding: 10vh 0;
    height: 100%;
    align-items: flex-start;
}

.registerinfoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 40px;
  width: 460px;
}

.registerlogo{
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
    text-align: start;
    margin-top: 80px;
    justify-content: flex-start;
    width: 100%;

}

.logo_img {
  height: 25px;
  width: auto;
  object-fit: cover;
}

.logo_text {
  color: #3a60e8;
  font-weight: 700;
  font-size: 25px;
  margin-left: 10px;
  font-family: "Lucida Grande"; //'Lucida Sans'; // 'Lucida Grande', , Arial, sans-serif;
}

.title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #3a60e8;
}

.title_text {
  color: rgba(59, 65, 87, 1);
  font-size: 20px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  margin-left: 10px;
}

.info_desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #3b4157;
  text-align: start;
  margin-top: -10px;
}

.registerInputMainCopntainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.signUpContainer {
  display: flex;
  width: 456px;
  background-color: white;
  box-shadow: 5px 5px 15px 10px #e2e0e4;
  padding-bottom: 40px;
  justify-content: center;
  padding-top: 20px;
  flex-direction: column;
  align-items: center;
  // padding-left: 60px;
  // padding-right: 60px;
}

.title_register {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  color: #3b4157;
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  margin-bottom: -20px;
  width: 340px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 0px;
  margin-top: 10px;
  width: 340px;
}

.inputlevel {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #4e556a;
}

.input {
  width: 100%;
  border-style: solid;
  border-color: rgba(224, 224, 224, 1);
  border-radius: 5px;
  height: 40px;
  // padding-inline: 10;
  border-width: 3px;
  font-size: 20px;
  margin-top: -10px;
}

.tcContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
}

.tctext1 {
  color: rgba(64, 62, 76, 1);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.tctext2 {
  cursor: pointer;
  margin-left: 2px;
  margin-right: 3px;
  color: rgba(58, 96, 232, 1);
}

.createAccountButton {
  width: 360px;
  height: 44px;
  background-color: #3a60e8;
  display: flex;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  // margin-top: 70px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  margin-bottom: 25px;
}

.createAccountButtonText {
  font-size: 20px;
  color: #ffffff;
  font-weight: 500;
}

.haveaAccountContainer {
  display: flex;
  width: 420px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.haveaAccountText {
  color: rgba(64, 62, 76, 1);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
}

.gotoLoginButton {
  color: rgba(58, 96, 232, 1);
  cursor: pointer;
  margin-left: 2px;
  margin-right: 3px;
}

.waterMarktext {
  font-weight: 400;
  font-size: 16px;
  color: #737687;
  margin: 1% 3%;
  display: flex;
  justify-content: end;
}
.google-img {
  background-color: #fff;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 5px
}

@media only screen and (max-height: 600px) {
  .waterMarktext {
    display: none;
  }

}
