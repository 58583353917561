@import "../abstracts/mixins.scss";
@import "../abstracts/variables.scss";

.NotifyWrap {
  @include flex($align: center, $justify: flex-start, $direction: row);
  width: 100%;
}

.NotifyTopGrid {
  display: flex;
  width: 100%;
  cursor: pointer;
}

.NotifyTopGrid:hover {
  background: #F4F5F8
}
.NotifyImg {
  @include flex($align: flex-start, $direction: column);
  height: 100%;
  padding: 2vh;
}
.NotifyText {
  @include flex( $justify: flex-start, $direction: column);
  width: 90%;
  padding: 1vh;
}
.NotifyMainText {
  padding: 1vh;
  @include flex($justify: flex-start, $direction: row);
  color: #201f1e;
}
.NotifySubText {
  color: #7f7b78;
  margin-left: .5rem;
  margin-bottom: .5rem;
}

.NotifyLine {
  padding-right: 1vh;
}
.NotifyImg1 {
  width: 5vh;
  height: 5vh;
  background-color: #e5eeff;
  border-radius: 10px;
}
.NotifyPagination {
  @include flex($align: center, $justify: center, $direction: column);
}

.width20 {
  width: 20%;
}
.width80 {
  width: 80%;
}
.headerNotify {
  padding-left: 5vh;
  padding-top: 5vh;
}
.Notifynav {
  @include flex($align: center, $justify: flex-start, $direction: column);
  border-bottom: 1px solid #d7d9dd;
}
.Notifynav1 {
  @include flex($align: center, $justify: flex-start, $direction: column);
}
.typo1 {
  padding: 2vh;
  cursor: pointer;
}
.typoselect {
  color: #0c47cc;
  border-bottom: 4px solid #0c47cc;
}
.MuiButton-outlined.view-decline-btn {
    margin: 1vh;
    border-color: #D0D0D0; 
    color: #010101; 
    height: 2rem;
    text-transform: none; 
  }
.MuiButton-contained.accept-btn {
    margin: 1vh;
    background-color: #3062D4;
    height: 2rem;
    text-transform: none; 
  }
  
