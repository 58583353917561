@import "../abstracts/mixins.scss";

.Order_detailContainer {
  @include customFlex(100%, null, center, column);
  margin-top: 60px;
  padding-right: 200px;
  @include font-size(16px);
}

.Order_detailTableTitle {
  @include customFlex(100%, space-between, center, row);
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 0.5px #b3b3b3 solid;
  padding-left: 40px;
}

.Order_detailText {
  font-family: Inter;
  @include font-size(16px);
  width: 12%;
  color: rgb(77, 84, 96);
}

.Order_detailText_1 {
  font-family: Inter;
  @include font-size(16px);
  width: 20%;
  color: rgb(77, 84, 96);
}
.green-text {
  color: green;
}

.red-text {
  color: red;
}

.yellow-text {
  color: yellow;
}

.button-cell1 {
  @include button-cell(rgb(255, 229, 229), red);
}

.button-cell2 {
  @include button-cell(rgb(255, 247, 198), rgb(206, 158, 0));
}
.button-cell3 {
  @include button-cell(#e5ffe9, rgb(33, 130, 0));
}

.text-op{
  color: #1E68F9;
}

.orders-overview-card{
  border: 1px solid #D8D8D8;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: .5rem 0;
  justify-content: space-evenly;
}
.orders-overview-card-1{
  min-width: 25%;
}
.orders-overview-card-2{
  min-width: 70%;
}

.service-order-text {
  color: #1E68F9 !important;
  text-decoration: underline;
  text-underline-offset: 4px;
}

@media only screen and (max-width: 1000px) {
  .orders-overview-card-1{
    width: 100%;
  }
  .orders-overview-card-2{
    width: 100%;
  }
}