@import "../abstracts/mixins.scss";
.outter-c1{
    width: 100%;
    height: 100vh; 
    background-color: white;
    display: flex;
    overflow: hidden;
}

.content-c1{
    width: 70%;
    background-color: white;
    @include flex-center;

}

.wrap-c1{
    flex-direction: row;
    @include flex-center;
    width: 80%;
}

.filenow-title-text{
    font-size: 26px;
    font-weight: 700;
    color: #1E68F9
}


.auth-or-text{
    font-size: 12px;
    font-weight: 500;
    color: #4A4A4A;
}
.wrapC1 .auth-input {
    min-width: 300px;
  }

.wrapC1 .action-btn {
    min-width: 320px;
    min-height: 40px;
    background-color: #eeeeee;
    text-transform: none;
  }

  .wrapC1 .action-btn-text{
    text-transform: none;
    font-size: 16px;
    padding-inline: 2vh;
    font-weight: 500;
    color: #627284;
}
.wrapC1 .action-btn:hover {
    background-color: #eeeeeeaf;
  }

  .otp-verification-text{
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    color: #4F566B
  }
  .MuiButton-contained.resend-otp-btn{
    margin: 1vh;
    color: #1E68F9;
    background-color: #eeeeee;
    text-transform: none; 
    min-height: 32px;
    align-self: flex-start;
  }

  .MuiButton-contained.resend-otp-btn:hover{
    background-color: #eeeeeeaf;
  }

  .wrapC1 .confirm-btn {
    min-height: 40px;
    background-color: #eeeeee;
    text-transform: none;
  }

  .wrapC1 .confirm-btn:hover {
    background-color: #eeeeeeaf;
  }
  
.cen{
    @include flex-center;
}

