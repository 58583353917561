.topserv_card_base {
  width: 200px;
  height: 220px;
  border-radius: 4px;
  border: 0.5px solid #cccccc;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 30px;
  margin-right: 32px;
  margin-top: 20px;
}

.topserv_card_title {
  width: 70%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  color: #666666;
}

.topserv_card_price {
  width: 70%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #666666;
  margin-top: 20px;
}

.topserv_card_button {
  width: 80%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  background-color: #467df7;
  margin-top: 60px;
  cursor: pointer;
  border-radius: 4px;
}
