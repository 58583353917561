.Lay-b1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1vh;
}
.featured-bound-text{
    font-size: 18px;
    font-weight: 500;
    color:#05294B;
}
.card-logo{
    min-width: 120px;
    min-height: 40px;
    background-color: white;
    display: flex;
    border: 1px solid #E1E1E1;
    justify-content: center;
    align-items: center;
}

.sidebar-c1{
    width: 40%;
    background-color: #F3F3FF;
    @include flex-center;
    height: 100%;
    color: #05294B;

}

.reviewer-name{
    font-size: 20px;
    font-weight: 500;
    color: #05294B;
}
.reviewer-designation{
    font-size: 16px;
    font-weight: 400;
    color: #05294B;
    line-height: .5;
}

.reviewer-content{
    text-align: center;
    justify-content: center;
    padding: 4% 8%;
    color: #5D7284;
    font-size: 16px;
    font-weight: 500;
}


@media only screen and (max-width: 550px), (max-height: 500px) {
    .sidebar-c1 {
        display: none;
    }
}

@media only screen and (min-width: 1800px) {
    .sidebar-c1{
        width: 25%;
    }
}