@import '../abstracts/mixins.scss';
@import "../abstracts/variables.scss";
.wrapC1{
@include flex($align:center,$justify:center,$direction:column);
    width:100%;
    padding: 1vh;
}
.wrapC2{
    @include flex($align:center,$justify:center,$direction:row);
    width:100%;
    height: 100%;
    padding: 1vh;
    }
.buttonText{
    text-transform: none;
    font-size: 14px;
    padding-inline: 2vh;
    font-weight: 500;
}

.buttonBg{
background-color: $primary-color;
}