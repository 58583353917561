.db-c1 {
  width: 100%;
  height: 25vh;
  border-radius: 2vh;
  background-color: #1e68f9;
}
.p-db {
  font-size: 10px;
  padding-inline: 2vh;
  width: 70%;
}
.dashboard-grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.dashboard-admin-card,
.dashboard-admin-card-w30 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
}
.db-c2 {
  width: 100%;
  padding-top: 1rem;
}

.dashboard-header-title-text{
  font-size: 14px;
  font-weight: 600;
  color: #33385B;
}

.db-tb-cell-total {
  font-size: 32px;
  font-weight: 700;
  color: #33385B;
}

.width40 {
  width: 40%;
}
.width40 {
  width: 40%;
}
.db-tb1 {
  border: 1.5px solid #c5bdff;
  border-radius: 5px;
}
.db-tb-cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: .5rem 1.5rem;
  border-bottom: 1px solid #ccc;
}

.db-c-a {
  font-size: 14px;
  font-weight: 600;
  color: #33385B;
  display: flex;
  align-items: center;
}
.db-c-b {
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #ccc;
  padding: .15rem .85rem;
  font-weight: 600;
  color: #303030;
  cursor: pointer;
}

.db-c-b:hover {
  background-color: #f8f4f4;
}
.cont-db-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding-inline: 3vh;
  height: 250px;
  @include respond-to(mid) {
    width: 100%;
  }
}
.cont-db-2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-inline: 3vh;
  gap: .5rem;
  @include respond-to(mid) {
    width: 100%;
  }
}

.db-cal-text {
  font-size: 1em;
  font-weight: 400;
  color: #545969;
}

.ad-cent {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.st-link {
  text-decoration: none;
  color: inherit;
}

.MuiButton-contained.view-report-btn{
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
  color: #303030;
  min-height: 24px;
  text-transform: none; 
  padding: .2vh 1.2vh;
}

.MuiButton-contained.view-report-btn:hover{
  background-color: #CCCCCC;
}

.width-filter{
  width: fit-content;
  height: fit-content;
  padding: 1vh;
  border:1px solid #D0D0D0;
  border-radius: .5vh;
  margin-block:1vh;
  background: transparent;
}

.fill-service-status{
  width: 35px;
  height: 10px;
  border-radius: 5px;
  color: black
}
.service-status-key{
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.service-status-value{
  font-size: 14px;
  font-weight: 700;
  color: #000;
}

@media (max-width: 1024px) {
  .dashboard-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on medium screens */
  }
}

@media only screen and (max-width: 950px) {
  .dashboard-admin-card {
    width: 100%;
  }
  .dashboard-admin-card-w30 {
    width: 100%;
  }
  .dashboard-grid {
    grid-template-columns: 1fr; /* Single column on smaller screens */
  }
}
