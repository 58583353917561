.ios-switch {
    position: relative;
    display: inline-block;
    width: 32px; /* Decreased width */
    height: 16px; /* Decreased height */
  }
  
  .ios-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 16px; /* Adjusted border-radius */
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 10px; /* Decreased height */
    width: 10px; /* Decreased width */
    left: 3px; /* Adjusted left position */
    bottom: 3px; /* Adjusted bottom position */
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(16px); /* Adjusted translation */
  }
  