$color_1: $secondary-color;
$color_2: #ffffff;
$color_3: white;
$font-family_1: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  "calibri";
$font-family_2: "Roboto";
$background-color_1: #ffffff;
$background-color_2: #eeeeee;
$background-color_3: $primary-color;
$background-color_4: #14c462;
$background-color_5: #f9f9f9;
$background-color_6: #298ffe;
$background-color_7: white;
$background-color_8: #324259;

@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";

* {
  box-sizing: border-box;
}
body {
  margin: 0px;
  font-family: $font-family_1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}
.main-heading {
  height: 100vh;
  background-size: cover;
  background-color: $background-color_1;
  overflow: auto
}
.sub-heading {
  @include flex-center;
  flex-direction: column;
}
.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-inline: 10%;
  flex-wrap: wrap;
}
.logo-container {
  display: flex;
  flex-direction: row;
}
.header-contents {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 22px;
  p {
    margin-right: 20px;
  }
}
.navbar-list {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 20px;
  color: $color_1;
  li {
    padding: 5px 15px;
    a {
      text-decoration: none;
    }
  }
}
.login-button {
  @include button-styles($background-color_2, $color_1, 16px, 5px, 35px);
}
.register-button {
  @include button-styles($background-color_3, $color_2, 16px, 5px, 35px);
}
.intro-section {
  display: flex;
  width: 80%;
  margin: 5% auto;
  max-width: 1400px;
}
.intro-section-details {
  width: 40%;
}
.get-started-button {
  @include button-styles($background-color_3, $color_3, 16px, 7px, 35px);
}
.talk-experts-button {
  @include button-styles($background-color_4, $color_3, 16px, 7px, 35px);
}
.intro-section-images {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.intro-img{
	width: 80%;
	height: 70%;
}
.social-media-btns {
  display: flex;
  width: 85%;
  justify-content: space-between;
  margin: 0 auto;
}
.social-links {
  display: flex;
  justify-content: space-between;
  width: 15%;
}
.companies-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $background-color_5;
  padding: 10px 0px;
  font-family: $font-family_2;
  font-size: 15px;
  font-weight: normal;
}
.companies-list {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 1400px;
}
.our-services {
  width: 100%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1400px;
}
.services-list {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    border-radius: 20px;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 15px 31px;
    font-size: 16px;
  }
}
.itr-filing {
  margin-top: 20px;
  width: 90%;
  padding: 25px;
  border-radius: 10px;
  background-color: $background-color_6;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1400px;
}
.itr-filing-boxes {
  background-color: $background-color_7;
  border-radius: 5px;
  margin-right: 10px;
  margin-top: 5px;
  width: 170px;
  padding-left: 10px;
  h1 {
    font-size: 16px;
  }
  p {
    font-size: 12px;
  }
}
.why-legally {
  width: 85%;
  text-align: center;
  max-width: 1400px;
  margin: 2% auto;
}
.sub-why-legally {
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sub-why-legally-cards {
  width: 30%;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size: 20px;
  font-weight: 600;
  border-radius: 4px;
  img {
    width: 50px;
    height: 100px;
  }
}
.lowest-price-challenge {
  width: 85%;
  text-align: center;
  border-radius: 10px;
  background-color: $background-color_6;
  color: $color_3;
  max-width: 1400px;
  margin: 2% auto;
  padding: 25px;
  p {
    font-size: 20px;
    font-weight: 600;
  }
}
.encry-priv-data {
  margin-top: 10px;
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1400px;
  margin: 2% auto;
}
.encryption {
  width: 300px;
}
.encryption-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  p {
    padding: 20px;
  }
}
.six-point {
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.six-point-wise {
  display: flex;
  flex-direction: row;
  margin-right: 50px;
}
.six-point-wise-reverse {
  display: flex;
  flex-direction: row-reverse;
  margin-left: 50px;
}
.first-three {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.second-three {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dot {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 1px 1px 1px 1px gray;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
.how-legally-works {
  width: 85%;
  text-align: center;
  max-width: 1400px;
  margin: 5%;
}
.online-support {
  width: 75%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2%;
}
.app-store {
  width: 85%;
  background-color: $background-color_6;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  max-width: 1400px;
  color: $color_3;
  z-index: 1000;
}
.spacer {
  height: 25vh;
}
.main-footer {
  background-color: $background-color_8;
  width: 100%;
  margin-top: -10%;
}
.sub-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  align-items: center;
  color: $color_3;
  font-size: 18px;
  font-weight: bold;
}

.footer-title {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.links-text {
  color: $background-color_6;
  text-decoration: underline;
  font-size: 14px;
  text-underline-offset: 4px;
}

.links-text:hover {
  color: #a4c6db
}

.policies-container {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  overflow: auto;
  height: 100vh;
}
.privacy-list-style {
  list-style: none;
}

@media only screen and (max-width: 800px) {
	.intro-section-details {
		width: 50%;
	}
	.intro-section-images {
		width: 50%;
	}
	.intro-img{
	width: 100%;
	height:100%;
}
}
@media only screen and (max-width: 600px) {
  .intro-section {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .intro-section-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px auto;
    p {
      font-size: 26px !important;
    }
  }
  .intro-section-images {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px auto;
    img {
      height: 400px;
    }
  }
  .companies-container {
    display: none;
  }
  .our-services {
    display: none;
  }
  .itr-filing {
    display: none;
  }
  .why-legally {
    display: none;
  }
  .lowest-price-challenge {
    display: none;
  }
  .encry-priv-data {
    display: none;
  }
  .six-point {
    display: none;
  }
  .how-legally-works {
    display: none;
  }
  .online-support {
    display: none;
  }
  .app-store {
    display: none;
  }
  .main-footer {
    display: none;
  }
  .talk-experts-button {
    display: none;
  }
  .get-started-button {
    width: 100%;
    margin: 70px auto;
    padding: 20px;
    font-size: 30px;
    font-weight: bold;
  }
  .register-button {
    margin: 0;
  }
}
