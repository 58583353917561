.ser-stat-wrap {
  border: 2px solid #C5C5C5;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 2vh;
  width: 75%;
  margin-block: 2vh;
}

.width25 {
  width: 25%;
}

.ss-width60 {
  width: 60%;
}

.width45 {
  width: 45%;
}

.width30 {
  width: 30%;
}

.typography-ser {
  color: #1E68F9;
  background-color: #CFF5F6;
  padding-inline: 3vh;
  padding-block: 0.5vh;
}

.ser-stat-wrap2 {
  display: flex;
  flex-direction: row;
}

.ser-stat-A {
  width: 75%;
  display: flex;
  flex-direction: row;
}

.ser-stat-B {
  width: 25%;
}

.ser-stat-A-1 {
  width: 70%;
}

.ser-stat-A-2 {
  width: 30%;
}

.status-input-grid {
  width: 100%;
  height: 70vh;

}

.status-input-grid-A {
  height: 30%;
  background-color: #F6FBFE;
  width: 100%;
  @include flex($align: center, $justify: center, $direction: row);
  padding: 5vh;
  margin-block: 2vh;
  border: 1px dashed #C4C4C4;
  cursor: pointer;

}

.drop-logo-img {
  padding-inline: 1vh;
}

.status-input-grid-B {
  height: 60%;
  width: 100%;
  @include flex($align: center, $justify: center, $direction: row);
  padding: 5vh;
  margin-block: 2vh;
  border: 1px solid #C4C4C4;
}

.status-input {
  text-decoration: none;
  width: 100%;
  height: 100%;
}

.status-input::-webkit-file-upload-button {
  background-color: none;
  display: none;
}

.file-label {
  cursor: pointer;

  border-radius: 4px;
  background-color: none;
}

.file-label span {
  @include flex($align: center, $justify: center, $direction: row);

  margin-right: 8px;
}

.file-input {
  @include flex($align: center, $justify: center, $direction: row);
  border: 2px solid black;

}

.border-left {
  @include border(1px, #c4c4c4d6, left);
}

.ss-g1-border {
  @include border(1px, #c4c4c4d6);

}

.ss-grid-50 {
  width: 50%;
}

.ss-grid-cen {
  @include flex($align: center, $justify: center, $direction: row);

}

.ss-grid-end {
  @include flex($align: center, $justify: flex-end, $direction: row);

}

.ss-padding {
  @include padding(1vh);
}

.ss-margin-b-3 {
  @include margin(2vh, bottom);
}

.ss-bg-text {
  color: #1E68F9;
  background-color: #CFF5F6;
}

.chat-container .rcw-send .rcw-send-icon {
  height: 30px;
}

.highlight-send-button .rcw-send .rcw-send-icon {
  filter: invert(26%) sepia(100%) saturate(2794%) hue-rotate(159deg) brightness(99%) contrast(100%);
}

.chat-container .loader {
  display: none;
}

.chat-container .rcw-sender {
  align-items: center;
}

.chat-container .rcw-message {
  margin: 0px 10px;
}

.chat-container .rcw-message .rcw-response {
  margin-top: 5px;
}

.chat-container .rcw-message .user-name {
  font-size: 14px;
  font-weight: 600;
}

.chat-container .rcw-message .user-timestamp {
  font-size: 10px;
}

.hide-input .rcw-new-message,
.hide-input .rcw-send {
  display: none !important;
}