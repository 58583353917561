.bcw-w{
    width: 150px;
    margin: 1vh;
}
.bcw-w-blue{
    background-color: #F6F8FD;
    border: 2px solid #8EA7FF;
}
.bcw-w-orange{
    background-color: #FFF0E4;
    border: 2px solid #FFBA88;
}
.bcw-w-green{
    background-color: #EEFFEF;
    border: 2px solid #A7FFAA;
}
.button-comp-wrap{
    @include customFlex(100%, center, center, column);

    border-radius: 1vh;
    padding-inline: 1vh;
    padding-block: 0.5vh;
    // flex-wrap: wrap;

}
.button-comp-wrap-15{
    @include customFlex(15%, center, center, row);
    margin: 1vh;

}

.button-comp-wrap-70{
    @include customFlex(50%, center, center, row);
    margin: 0.5vh;





}