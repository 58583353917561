.em-g-1 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.em-g-1-1 {
  min-height: 100vh;
}
.border-right-gray{
  border-right: .5px solid #D8D8D8;

}
.ms-half{
  margin-left: .5rem
}
.em-g-1-2 {
  display: flex;
  flex-direction: column;
}
.w-80 {
  width: 80%;
}
.w-60 {
  width: 60%;
}
.w-30 {
  width: 30%;
}
.p-2 {
  padding: 2rem;
}
.w-20 {
  width: 20%;
}

.wrap-em {
  min-width: 240px;
  width: 'fit-content';
  display: flex;
  justify-content: space-between;
}

.em-blue {
  color: #1f35ffde;
}

.em-red {
  color: #ff0000de;
}

.em-btn-1 {
  width: 100%;
  border: none;
  background: none;
  background-color: #1e68f9;
  padding: .25rem .5rem;
  display: flex;
  justify-content: center;
  color: white;
  border-radius: 5px;
  height: 2rem;
  align-items: center;
}

.btn-1-text {
  font-size: .8em;
  font-weight: 700;
}
.employee-list-title {
  font-size: 18px;
  font-weight: 700;
}
.em-bg {
  background-color: #f6f8fd;
  height: 7vh;
}
.em-typo-1 {
  background-color: #d9d9d9;
  font-weight: 700;
  font-size: 10px;
  padding: 1vh;
  border-radius: 5px;
}

.em-cont-grid {
  justify-content: flex-end;
  align-items: flex-end;
  padding: 1vh;
}
.inp-em {
  padding: 1vh;
  width: 100%;
}

.inp-feild-em {
  border: 1px solid #d9d5d5;
  padding: 1vh;
  width: 100%;
  border-radius: 4px;
  height: 2em;
  margin: .25rem 0;
}
.inp-feild-em2 {
  background-color: white;
  border: none;
  width: fit-content;
  border-radius: 1vh;
}
.em-ps {
  background-color: #f1f1f1;
  padding: 1.5vh;
}

.p-1-em {
  font-size: 12px;
}
.em-wrap-g1 {
  padding: 1vh;
}

.em-g1 {
  width: 30%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.test2-em {
  font-size: 10px;
}

.test1-em {
  font-weight: 600;
}

.embtn-blue {
  background-color: #1e68f9;
  color: white;
  min-width: 7rem;
  border-radius: 5px;
}

.MuiButton-contained.emp-cancel-btn{
  background-color: #fff;
  border: 1px solid #000;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
  color: #353535;
  min-height: 24px;
  width: 45%;
  text-transform: none; 
  padding: .5vh 1.2vh;
}

.MuiButton-contained.emp-cancel-btn:hover{
  background-color: #e4dede;
}

.MuiButton-contained.emp-save-btn{
  background-color: #1E68F9;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
  color: #fff;
  min-height: 24px;
  width: 45%;
  text-transform: none; 
  padding: .5vh 1.2vh;
}

.role-manag-btn {
  border-radius: 1vh;
  background-color: white;
  border: 1px solid black;
  min-width: 12rem;
  width: auto;
  height: 2rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.role-manag-btn:hover {
  background-color: #eeebeb;
  border: 1px solid #bbb8b8;
}
