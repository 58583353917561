.serviceCard{
    width: 100%;
    height: 100%;
    padding: .75rem 1.25rem;
    border: .5px solid #d6d6d6;
    border-radius: 10px;
    background: #f1f1f3
}
.servicesWrap{
    display: inline-block;
    width: 290px;
    min-height: 220px;
    margin-block: 1vh;
    margin-right: 2vh;
}

.services-btn{
    width: fit-content;
    border: none;
    background: none;
    background-color: #3B82F6;
    padding: 1vh 1rem;
    color:#fff;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
}

.services-btn:disabled{
    background-color: #a7a9ac;
}
.service-img-placeholder{
    width: 240px;
    height: 120px;
    border: .5px solid #ccc;
    background: #D7E4FF;
    border-radius: 10px;
}

.service-card-starts-cont {
    border: 1px solid #d2d2d2;
    padding: .25rem 1rem;
    border-radius: 5px;
    background: #fff;
}
.service-card-starts-txt {
    font-weight: 600;
    font-size: 1em;
}

.service-card-serviceType-cont {
    width: fit-content;
    border: 1px solid #d2d2d2;
    padding: .05rem .75rem;
    border-radius: 20px;
    background: #fff;
    color: #000012
}

