// Variables
$primaryColor: rgb(93, 29, 253);
$secondaryColor: rgb(183, 183, 183);

@import "../abstracts/mixins.scss";

// Styles
.background {
  @include flex-center;
  width: 100%;
  background-color: rgba(247, 250, 252, 1);
  height: 100vh;
}

.mainContainer {
  @include customFlex(55%, center, center, column);
  @include border-style(1px, #e2e0e4);
  height: 100vh;

  // Responsive adjustments
  @media only screen and (max-width: 1200px) {
    width: 70%;
  }

  @media only screen and (max-width: 800px) {
    width: 80%;
  }
}

.otpContainer {
    background-color: rgb(252, 252, 253);
    width: 50%;
    padding-top: 30px;
    padding-bottom: 20px;
    box-shadow: 4px 4px 4px 4px rgb(210, 209, 209);
    border-radius: 15px;
    margin-top: 100px;

  // Responsive adjustments
  @media only screen and (max-width: 1200px) {
    width: 70%;
  }

  @media only screen and (max-width: 800px) {
    width: 80%;
  }
}

.otpContainerTitle {
  @include border-style(0.5px, rgb(240, 246, 251));
  border-bottom-color: rgb(183, 183, 183);
  padding-left: 30px;
  padding-bottom: 30px;
}

.mainOTPcontainer {
  @include customFlex(100%, center, center, column);
  padding-top: 60px;
}

.innermainOTPcontainer {
    width: 90%;
    display: flex;
    justify-content: center;

}

.button_varify {
    width: 90%;
    height: 45px;
    background-color: $primaryColor;
    border-radius: 7px;
    @include flex-center;
    margin-top: 50px;
    cursor: pointer;
}

.atlastext {
  font-weight: 600;
  color: black;
  font-size: xx-large;
}

.text2 {
  color: $primaryColor;
  font-weight: 500;
  margin-top: 35px;
  cursor: pointer;
}

.headingText {
  color: #626363;
  text-align: left;
}

.fontsize1 {
  font-size: 20px;
  font-weight: 500;
}

.fontsize2 {
  font-size: 17px;
  color: white;
  font-weight: 500;
}

.otpinputContainer {
    @include flex-center;
    justify-content: space-between;
    color: black;
}

.inputstyle_indv {
    height: 100%;
    width: 100%;
    border-radius: 7px;
    font-size: 50px;
    @include border-style(1px, $secondaryColor);
    color: rgba(79, 86, 107, 0.7);
}

.margintopneg {
  margin-top: -100px;
}

.watermarkleft {
  position: absolute;
  top: 20px;
  right: 40px;
  color: #737687;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
.wrapOTP{
  @include flex($align:flex-start,$justify:flex-start,$direction:column);
  width:25%;
  height: 100%;
  padding: 1vh;
  }
// Media Queries
@media only screen and (max-width: 900px) {
    .inputstyle_indv {
        font-size: 25px;
        height: 100%;
        width: 100%;
        margin-left: -10px;
    }
    .innermainOTPcontainer{
       

        width: 70%;
    }
    .button_varify {
        width: 70%;
    }

  .watermarkleft {
    left: 10%;
  }
}

@media only screen and (max-width: 400px) {
  .mainContainer {
    width: 100%;
  }

  .otpContainer {
    width: 90%;
  }

  .inputstyle_indv {
    font-size: 20px;
    height: 40px;
    width: 45px;
    margin-left: -10px;
  }

  .button_varify {
    width: 70%;
  }

  .watermarkleft {
    left: 10%;
  }

  .margintopneg {
    margin-top: 0px;
  }
}
