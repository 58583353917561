@import "../abstracts/mixins.scss";

.legaldoc_select {
  margin-left: 40%;
  color: #878c93;
}

.legaldoc_text2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #4d4d4d;
  margin-top: 30px;
  margin-right: 50px;
}

.legaldoc_cardContainer {
  @include customFlex(100%, center, center, row);

  flex-wrap: wrap;

  margin-top: 20px;
}

.ld-wrap1{
  @include customFlex(80%, space-between, column);

}
.ld-border{
  border: 1px solid #C0C0C0;
  border-radius: 10px;
  padding-inline: 5px;
  padding-block: 5px;
}
.ld-bg{
  background-color: #F6F8FD;

}

.ld-w1{
  width: 100%;
}
.ld-w2{
  width: 20%;
}
.ld-prof{
  padding: 2vh;
  background-color: #F6F8FD;
}
.ld-w-1{
  padding:1vh;
}
.ld-inp-1{
  display:none;
}
.inp-ld{
  border: none;
  background-color: #F5F5F5;
  padding: 2vh;
  margin: 1vh;
}
.custom-file-upload{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F6FBFE;
  width: 90%;
  padding: 2vh;
  height: 20vh;
  border: 2px dashed #E6E6E6;
}

.img-ld{
  padding-inline: 1vh;
}
.ld-text-1{
  font-size: 12px;
  color: #8291B0;
  font-weight: 400;
}

.ld-padd{
  padding-block: 2vh;
}