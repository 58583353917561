@import "../abstracts/mixins.scss";

.newService_select {
  margin-left: 40%;
  color: #878c93;
}

.newService_addContainer {
  background-color: #d2d1d1;
  height: 120px;
  margin-top: 20px;
  border-radius: 4px;
}
.newser_main_Container {
  @include customFlex(column);
  width: 100%;
  margin-top: 30px;
  padding-bottom: 25px;
}

.newser_cardContain {
  @include customFlex(100%, null, center, row);
  flex-wrap: wrap;
}

.newServ_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #545969;
}

.paddingbottom60 {
  padding-bottom: 60px;
}
