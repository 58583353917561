.das-set {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.setup-dashboard-toggle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.s-d-t-1 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #1e68f9;
  border-radius: 10vh;
  padding-inline: 1vh;
}

.s-d-t-g {
  margin: 1vh;
  color: white;
}

.sdtg-w {
  background-color: white;
  border-radius: 10vh;
  padding-inline: 2vh;
  color: black;
}

.setup-g1 {
  background-color: #1e68f9;
  height: 180px;
  border-radius: 1vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  color: white;
}

.setup-banners {
  display: flex;
  width: 100%
}

.setup-banners-1 {
  width: 75%;
}

.setup-banners-2 {
  width: 25%;
}

.admin-setup-banners-1 {
  width: 70%;
}

.admin-setup-banners-2 {
  width: 30%;
}

.setup-g1-b {
  width: 30%;
  background-color: #1e68f9;
  border-radius: 1vh;
  margin-left: 2vh;
  height: 100%;

  @include respond-to(tablet) {
    margin: 1vh;
    width: 100%;
    height: 20vh;
  }
}

.setup-img1 {
  width: 100%;
  height: 100%;
}

.setup-g2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f1f1f1;
  margin-block: 2vh;
  padding: 3vh;
  height: 100%;
}
.st-configure-data {
  display: flex;
  margin-top: 1rem;
  gap: 0.5rem
}
.st-configure-data-subtitle {
  font-size: 14px;
  font-weight: 500;
  color: #636a76;
}
.setup-g2-a {
  width: 70%;
  height: 100%;
  gap: .5rem;
  display: grid
}

.setup-g2-b {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: auto 0 0 0;
}

.setup-g2-a-block {
  background-color: white;
  width: 100%;
  border-radius: 1vh;
  padding: 1.5vh;
}

.setup-b-1 {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.setup-b-2 {
  width: 70%;
}
.setup-b-3 {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.setup-b-3-text {
    color: #1e68f9;
    font-size: 12px;
    font-weight: 600;
    text-transform: none; 
  }
.setup-g2-b1 {
  background-color: white;
  border: 1px solid #d5d5d5;
  padding: 1vh;
}

.setup-g2-b11 {
  padding: 1vh;
}

.setup-body {
  color: #636a76;
  padding: 3vh;
}

.setup-button {
  display: flex;
  justify-content: flex-end;
  padding: 1vh;
}

.st-switch {
  width: 10vw;
  background-color: #1e68f9;
  font-size: 10px;
  border-radius: 3vh;
  padding: 1vh;
  font-weight: 500;
  color: white;
  @include respond-to(tablet) {
    width: 20vw;
  }
  @include respond-to(small) {
    width: 40vw;
  }
}
.st-width50 {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.st-bg-w {
  background-color: white;
  border-radius: 3vh;
  color: black;
  padding-block: 0.5vh;
}

.st-cent {
  align-items: center;
  margin-bottom: 2vh;
}
.st-data-list {
  padding: 0.5rem 1rem;
}

.MuiButton-contained.emailus-btn {
  background-color: #1E68F9;
  min-height: 2rem;
  text-transform: none;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
}
@media only screen and (max-width: 950px) {
  .admin-setup-banners-1, .setup-banners-1 {
    width: 100%;
  }
  
  .admin-setup-banners-2, .setup-banners-2 {
    display: none;
  }
}

@media only screen and (max-width: 1100px) {
  .setup-g2-a {
    width: 100%;
  }

  .setup-g2-b {
    width: 100%;
  }
  .st-configure-data {
    flex-direction: column;
  }
}

@media only screen and (max-width: 500px) {
  .setup-b-3-text{
    display: none;
  }
  .setup-banners {
    flex-direction: column;
  }
  .setup-banners-1 {
    width: 100%;
  }
  .setup-banners-2 {
    width: 100%;
  }
}
