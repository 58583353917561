@import "../abstracts/mixins.scss";
.wrapC3{
@include flex($align:center,$justify:center,$direction:column);
margin:2vh;
padding: 2vh;
}
.resetpass_background{
    @include customFlex(100%, center, center, null);
   height: 100vh;
   z-index: 1;
}

.mainContainer {
  @include customFlex(55%, center, center, column);
  border-width: 1px;
  border-color: #e2e0e4;
  border-style: solid;
  height: 100vh;
}

.containtContainer {
  background-color: rgb(252, 252, 253);
  padding-top: 30px;
  padding-bottom: 50px;
  box-shadow: 8px 8px 12px 12px rgba(156, 152, 152, 0.313);
  border-radius: 5px;
  @include customFlex(450px, center, center, column);
}

.containertitleText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #3b4157;
  @include customFlex(75%, flex-start, center, null);
}

.containertitle_innerText {
  color: rgba(58, 96, 232, 1);
  margin-inline: 5px;
}

.atlastext {
  font-weight: 600;
  color: black;
  font-size: xx-large;
}

.inputContainer {
  padding-bottom: 0px;
  @include customFlex(75%, null, null, column);
}

.inputlevel {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #4e556a;
}

.input {
  width: 100%;
  border-style: solid;
  border-color: rgba(224, 224, 224, 1);
  border-radius: 5px;
  height: 45px;
  padding-inline: 10;
  border-width: 3px;
  font-size: 20px;
}

.sendOTPButton {
  @include customFlex(77%, center, center, null);
  height: 50px;
  background-color: #3a60e8;
  border-radius: 7px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  margin-top: 35px;
  margin-bottom: 25px;
}

.sendOTPButtonText {
  font-size: 15px;
  color: #ffffff;
  font-weight: 500;
}

.watermarkleft {
  position: absolute;
  top: 20px;
  right: 40px;
  color: #737687;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
.resend-otp{
  display: flex;
  justify-content: end;
}

.resend-otp-text{
  text-decoration: underline;
}

.resend-otp-text:hover{
  color: #3a60e8;
}