.cd-wrap-1{
    @include flex( center, flex-start, column);
  width:100%;
  }
  .cd-wrap-2{
      @include flex( flex-start, flex-start, column);
      padding-bottom: 5vh;
      width: 100%;
      gap: 2rem;
      flex-wrap: wrap;
    }
    
  .cd-section-1{
      @include flex( center, row);
  
  }
  
  .cd-search{
    height: 1.8rem;
    border-radius: 4px;
    padding: .5rem;
  }

  
  .bar-cd{
      width: fit-content;
      padding: .5rem;
      border: solid 1px #DEDEDE;
      border-radius: 5px;
      gap: .5rem;
      @include flex( center, space-between, row);
  
  }
  
  .blue-cd{
  background-color: #F6F8FD;
  }
  
  
  .orange-cd{
    background-color: #FFF0E4;  
  }
  
  
  .green-cd{
      background-color: #EEFFEF;
  }

  .span-cd{
    background-color: #22B378;
    padding: 1vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: white !important;
    border-radius: 5px;
    width: 70%;
  }

  .span-cd:hover, .span-cd:focus{
    outline: 'none'
  }

.bg-cd-table{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
    flex-direction: column;
    width: 100%;
    overflow: auto;
}
.client-name:hover{
  color: #1E68F9;
  text-decoration: underline;
  text-underline-offset: 4px;
}