@mixin flex($align: stretch, $justify: flex-start, $direction: row) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
}

@mixin custom-width($default: 100%, $mobile: 80%, $inbetween: 90%) {
  width: $default;
  @media screen and (max-width: 767px) {
    width: $mobile;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: $inbetween;
  }
}

@mixin const_width($default: 100%) {
  width: $default;
}

// Define mixin for font size
@mixin font-size($size, $weight: 400) {
  font-size: $size;
  font-weight: $weight;
}
// Mixins
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin border-style($width, $color) {
  border-width: $width;
  border-color: $color;
  border-style: solid;
}

@mixin customFlex(
  $width: 100%,
  $justify: center,
  $align: center,
  $direction: column
) {
  width: $width;
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}
@mixin button-styles(
  $bg-color,
  $text-color,
  $font-size,
  $padding-y,
  $padding-x
) {
  background-color: $bg-color;
  border-radius: 5px;
  color: $text-color;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: $font-size;
  margin: 5px 15px;
  padding: $padding-y $padding-x;
}
@mixin button-cell($bgColor, $textColor) {
  display: inline-block;
  padding: 2px;
  border-radius: 5px;
  background-color: $bgColor;
  color: $textColor;
  text-align: center;
  width: 4vw;
}



@mixin custom-box($bg-color, $border-color) {
  background-color: $bg-color;
  border: 1px solid $border-color;
}

@mixin border($size, $color, $sides: null) {
  @if $sides == null {
    border: $size solid $color;
  } @else {
    @each $side in $sides {
      border-#{$side}: $size solid $color;
    }
  }
}

@mixin padding($pad, $side: null) {
  @if $side == null {
    padding: $pad;
  } @else {
    padding-#{$side}: $pad;
  }
}

@mixin margin($size, $side: null) {
  @if $side == null {
    margin: $size;
  } @else {
    margin-#{$side}: $size;
  }
}


@mixin respond-to($breakpoint) {
  @if $breakpoint == small {
    @media (max-width: 600px) { @content; }
  } @else if $breakpoint == tablet {
    @media (max-width: 1000px) { @content; }}
    @else if $breakpoint == mid {
      @media (max-width: 1200px) { @content; }
  } @else if $breakpoint == desktop {
    @media (max-width: 1500px) { @content; }
  }
}