@import "../abstracts/mixins.scss";

// service card container
.myServicescardContainer{
   
    @include customFlex(100%, null, center, row);
   
    flex-wrap: wrap;
  
    border: none;
   
}

.blue-block-ms{
    width:100%;
    background-color: #1E68F9;
    height: 100%;
    border-radius: 7px;
}

.wrapMS{
  padding: 2vh;  
}
.myserwrap{
    padding-block: 0.5vh;
    flex-wrap: wrap;
}
.g-sc2-btn{
    @include customFlex(100%, flex-end, center, row);

}
.services-btn-1{
    background-color: #1E68F9;
    border: none;
    color: white;
    padding-block: 0.5vh;
    padding-inline: 2vh;
    border-radius: 1vh;
}