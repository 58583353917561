.btn-mark{
    background-color: white;
    border: none;
    text-decoration: underline;
    text-underline-offset: 3px;
    color: #1E68F9;
}
.np-cont{
    padding-block: 1vh;
}
.notif-popup-wrap{
    width: 30%;
    height: 100%;
    border-radius: 1vh;
    padding: 2vh;
    border: 1px solid #d1d1d1;

}
.np-w1{
    display: flex;
    justify-content: center;
    align-items: center;
}
.np-g1{
    width: 80%;
    font-weight: 700;
}

.np-g11{
    border-bottom: 1px solid #d1d1d1;
}
.np-g2{
    width: 20%;
}

.np-g-a{
    width: 20%;
    margin: 1vh;
    padding: 1vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.np-g-b{
    width: 80%;
}
.wrap-np{
    font-size: 12px;
}
.subtext-np{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
}

.sb-np{
    padding-inline-end: 1vh;
}