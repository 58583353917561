@import "./abstracts/mixins.scss";
@import "./abstracts/variables.scss";
@import "./base/reset.scss";
@import "./base/typography.scss";
@import "./components/landingpage-components.scss";
@import "./layout/grid.scss";
@import "./pages/LandingPage.scss";
@import "./pages/ChoseaService.scss";
@import "./pages/Otpscreen.scss";
@import "./themes/theme.scss";
@import "./vendors/bootstrap.scss";
@import "./pages/Register.scss";
@import "./pages/ResetPassword.scss";
@import "./pages/MyServices.scss";
@import "./components/ServicesSideBar.scss";
@import "./components/MyServicesCard.scss";
@import "./pages/ServiceDetails.scss";
@import "./pages/DashBoard.scss";
@import "./components/TopServicesCard.scss";
@import "./pages/NewService.scss";
@import "./components/NewServiceCard.scss";
@import "./pages/LegalDocumentation.scss";
@import "./components/LegalDocumentCard.scss";
@import "./components/Header.scss";
@import "./pages/OrderAndPayments.scss";
@import "./components/PaymentOrderDetailBlock.scss";
@import "./pages/SignIn.scss";
@import "./components/Loader.scss";
@import "./pages/ResetPassword2.scss";
@import './layout/auth-pages-layout.scss';
@import './pages/CreateAccount.scss';
@import './components/Layout.scss';
@import './components/Notify.scss';
@import './layout/Settings.scss';
@import './components/MiniSidebar.scss';
@import  './pages/SettingsProfile.scss';
@import './pages/SettingsPasswordLogin.scss';
@import './components/Switch.scss';
@import './components/CardV2.scss';
@import './pages/SettingsNotif.scss';
@import './pages/ComplianceCalendar.scss';
@import './pages/ServiceStatus.scss';
@import './pages/ClientDetails.scss';
@import './pages/ServiceManagement.scss';
@import './pages/ServiceCreateCategory.scss';
@import './pages/DashboardAdminSetup.scss';
@import './pages/SettingsRole.scss';
@import './pages/NotificationAdmin.scss';
@import './pages/DashboardAdmin.scss';
@import './pages/CustomerManagement.scss';
@import './pages/EmployeeManagement.scss';
@import './components/ServicesCard.scss';
@import './components/Sidepop.scss';
@import './components/ButtonComponent1.scss';
.App {
  text-align: center;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.signupContainer {
  @include customFlex(450px, center, flex-start, column);
  background-color: white;
  box-shadow: 5px 5px 5px 10px #e2e0e4;
  padding-bottom: 50px;
  padding-top: 70px;
  padding-left: 60px;
  padding-right: 60px;
}

.h1 {
  color: black;
  font-weight: 500;
  font-size: 40px;
  margin-bottom: 60px;
  text-align: left;
  width: 500;
  opacity: 0.7;
  margin-top: 300px;
}

.h2 {
  font-size: 28px;
  color: black;
  font-weight: 500;
  padding-bottom: 30px;
}

.h3 {
  color: #0d121c;
  font-size: 22px;
  font-weight: 500;
  margin-left: 10px;
}

.text1 {
  color: #0d121c;
  font-size: 20px;
  width: 500px;
  text-align: start;
}

.signupButton {
  @include customFlex(100%, center, center, null);
  height: 55px;
  background-color: #4876f4;
  border-radius: 7px;
  margin-top: 70px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  margin-bottom: 40px;
}

.signInBUttonText {
  font-size: 20px;
  color: white;
  font-weight: 500;
}

.input1 {
  width: 400px;
  border-style: solid;
  border-color: #e2e0e4;
  border-radius: 5px;
  height: 45px;
  padding-inline: 10;
  border-width: 3px;
  font-size: 20px;
}

// common in every page for navigation container and and logo swsection
.Header {
  @include customFlex(100%, null, center, row-reverse);
  height: 45px;
  position: absolute;
  top: 0px;
  background-color: rgba(246, 248, 250, 1);
}

.headerText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #414552;
  margin-right: 25px;
  cursor: pointer;
}

.flexrowcont {
  @include customFlex(100%, flex-start, null, row);
  padding-left: 0;
}

.logo-Container {
  @include customFlex(20%, center, center, null);
  height: 40px;
  margin-top: 70px;
}

.logoimg {
  width: 130px;
  height: 26px;
  object-fit: cover;
}

.navigationContainer {
  width: 85%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 70px;
  flex-direction: row;
  position: relative;
  padding-left: 40px;
  // background-color: red;
}

.navigationText {
  font-size: 18px;
  color: rgba(129, 129, 129, 1);
  font-weight: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.navigationText2 {
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
  font-weight: 400;
}

.height1vh {
  height: 1vh;
}

.width20 {
  width: 20%;
  // margin-top: 20px;
}

.width20-ss{
  width: 20%;
  // margin-top: 20px;
}

.width30{
  width: 30%;
  // margin-top: 20px;
}

.width50{
  width: 50%;
}

.w-fit-content {
  width: fit-content !important;
}
.w-45 {
  width: 45%;
}
.myServicesContainer{
  display: flex;
  border-top-color: rgb(206, 205, 205);
  border-width: 0px;
  border-style: solid;
  border-top-width: 0.5px;
  flex-direction: column;
  flex-wrap: wrap;
}
.pt-1{
  padding-top: 1rem
}
.pt-2{
  padding-top: 2rem
}

.HeadingsearchContainer {
  width: 100%;
  height: 80px;
  display: flex;
  // justify-content: center;
  align-items: center;
  justify-content: space-between;
  // padding-left: 30px;
  flex-direction: row;
  // padding-right: 100px;
}

.heading {
  font-size: 26px;
  font-weight: 400px;
  color: rgba(77, 77, 77, 1);
}

.SearchContainer {
  width: 340px;
  height: 35px;
  display: flex;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  padding-right: 10px;
  flex-direction: row;
  // align-items: center;
  // justify-content: center;
  margin-right: 55px;
  font-size: 25px;
  color: rgba(135, 140, 147, 1);
  align-items: center;
}

.Searchinput {
  width: 95%;
  height: 100%;
  font-size: 17px;
  padding-left: 38px;
  border-width: 0px;
}

.Searchinput:focus {
  border: none;
}

.width90 {
  width: 90%;
  // margin-left: %;
}

.width95 {
  width: 95%;
}

.width100 {
  width: 100%;
}

.flex_spacbtwn_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.flex_column {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.ml-40 {
  margin-right: 40px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mt-30 {
  margin-top: 30px;
}

.watermarkleft {
  position: absolute;
  top: 20px;
  right: 40px;
  color: #737687;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.MuiButton-contained.save-btn{
  background-color: #1E68F9;
  font-size: 14px;
  font-weight: 500;
  border-radius: 3px;
  color: #fff;
  min-height: 40px;
  text-transform: none; 
  padding: .25rem 2rem;
  width: fit-content;
}

.MuiButton-contained.alert-cancel-btn {
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  border-radius: 3px;
  color: #303030;
  min-height: 40px;
  text-transform: none; 
  padding: .25rem 2rem;
  border: 1px solid #cacaca;
}

.MuiButton-contained.alert-cancel-btn:hover {
  background-color: #eae5e5;
}

.MuiButton-contained.alert-action-btn {
  margin: 1vh;
  text-transform: none; 
}

.MuiButton-contained.add-item-btn {
  background-color: #1E68F9;
  min-width: 2rem;
  width: 2rem;
  height: 2rem;
  text-transform: none; 
  border-radius: 50%;
}

.MuiButton-contained.add-service-btn {
  background-color: #1E68F9;
  min-width: 18px;
  width: 18px;
  height: 28px;
  text-transform: none; 
  border-radius: 50%;
}

.text-highlight-hover:hover {
  color: #1E68F9;
  text-decoration: underline;
  text-underline-offset: 4px;
}
.border-bottom-dashed {
  border-bottom: 1px dashed #696969;
}

.border-gray {
  border: 1px solid #dedede;
}

.placeholder-background {
  background: #c5c5c8;
}

.bg-f0f0f0 {
  background-color: #F0F0F0;
}
.bg-E4EBFF {
  background-color: #E4EBFF;
}

.view-details-btn{
  background-color: #DAE6FF;
  border-radius: 5px;
  padding: .4rem 1.5rem;
  border: none;
  color: #1E68F9;
  font-size: 12px;
  font-weight: 600;
}

.view-details-btn:hover{
  background-color: #bccef3;
}

/* React Quill */

.custom-quill-container {
  position: relative;
}

.custom-quill-container .ql-toolbar {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  border-top: 1px solid #ccc;
}
.custom-quill-container .ql-container {
  padding-bottom: 42px;
}
.ql-toolbar.ql-snow {
  min-height: 32px !important;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #f6f8fd !important;
  font-family: "Lato", "Roboto", serif !important;
  border-bottom-right-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.ql-snow .ql-formats {
  width: fit-content !important;
  display: flex !important;
  align-items: center !important;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #1E68F9 !important;
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  right: auto;
  left: 60px;
}

.ql-toolbar.ql-snow, .ql-container.ql-snow {
  font-family: "Lato", "Roboto", serif !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 6px !important;
  min-height: 150px;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0px !important;
}

.ql-toolbar.ql-snow .ql-formats:nth-child(3) {
  margin-inline-start: auto;
}

textarea{
  border: 1px solid #cdcdcd;
  border-radius: 6px;
}
//// common scc for pages end here

@media only screen and (max-width: 450px) {
  .mainContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .signupContainer {
    width: 350px;
  }
}
@media only screen and (max-width: 600px) {
  .abstract,
  .watermark {
    display: none;
  }
}
@media only screen and (max-width: 800px) {
  .width20-ss{
    width: 60%;
    // margin-top: 20px;
  }
}

