@import "../abstracts/mixins.scss";

.resetpass2_background {
  @include customFlex(100%, center, center, null);
  height: 100vh;
  z-index: 1;
}

.mainContainer_resetpass2 {
  @include customFlex(55%, center, flex-end, column);
  border-width: 1px;
  border-color: #e2e0e4;
  border-style: solid;
  height: 100vh;
}

.mainBox {
  @include customFlex(456px, center, center, column);

  background-color: rgb(252, 252, 253);

  padding-top: 30px;
  padding-bottom: 50px;
  box-shadow: 8px 8px 12px 12px rgba(156, 152, 152, 0.313);
  border-radius: 5px;
}

.resetpass2_boxTitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #3b4157;
  display: flex;
  align-items: center;
  width: 75%;
  justify-content: flex-start;
}

.resetpass2_boxTitle_innerText {
  color: rgba(58, 96, 232, 1);
  margin-inline: 5px;
}

.atlastext {
  font-weight: 600;
  color: black;
  font-size: xx-large;
}

.inputlevel {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #4e556a;
}

.resetpass2_button {
  @include customFlex(77%, center, center, null);
  height: 50px;
  background-color: #3a60e8;
  border-radius: 7px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  margin-top: 35px;
  margin-bottom: 25px;
}

.resetpass2_button_Text {
  font-size: 15px;
  color: #ffffff;
  font-weight: 500;
}
