.settings-profile-wrap{
    width: 100%;
    height: 100%;
    
}
.main-path-text{
    font-weight: 400;
    font-size: .75em;
    color: #2A2A2A;
    padding: .1rem;
    cursor: pointer
}
.main-path-text:hover {
    color: #1E68F9;
    text-decoration: underline;
    text-underline-offset: 4px;
}
.main-path-heading {
    display: flex;
    align-items: center;
    margin: .2rem 0 1rem 0
}
.main-path-heading-text {
    font-weight: 600;
    font-size: 20px;
    color: #090E24;
}
.sp-wrap1{
    width: 100%;
    @include customFlex(100%,  column);


}
.sp-wrap2 {
    width: 100%;
}
.sp-wrap3{
    height: 80%;
    
}
.sp-wr1{
    height:100%;
    width: 90%;
    @include customFlex(90%, flex-start, column);
}
.sp-wr11{
    width: 100%;
    border-top: 0.5px solid #D7D9DD;
    @include customFlex(100%, center, center, column);

}
.sp-wr2{
    @include customFlex(100%, flex-start, flex-start, column);
    padding-block:2vh;

}
.sp-wr3{
    height: 85%;
    align-items: center;
}
.sp-text{
    color: #626E98;
    padding-block: 1vh;
}
.sp-redirect{
    color: #6662FD;
    font-size: 10px;
    text-decoration: none;
}
.sp-icon{
    margin-right: 8px;
}

.sp-upload{
    height: 250px;
    @include customFlex(center, center, column);
    margin-block: 1vh;
    border: 1px solid #D7D9DD;
    border-radius: 5px;
}

.sp-up-text{
   height: 70px;
   padding: 1vh 0 3vh 0;
   color: #7A8198;
}
.sp-primary-contant-cont{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 1rem;
    width: 50%;
    flex-wrap: wrap;
}
.sp-inp{
    width:100%;
    min-height: 35px;
    padding: 1vh;
    border:1px solid #D0D0D0;
    border-radius: .5vh;
    margin-block:1vh;
    background: transparent;
}

.upload-logo-text{
    font-size: 14px;
    font-weight: 700;
    font-family: 'Inter';
    color: #363652;
  }

.MuiButton-contained.sp-btn1{
    background-color: #1E68F9;
    border: none;
    padding-inline: 3vh;
    padding-block: 1vh;
    font-size: 15px;
    border-radius: 1vh;
    color: white;
    margin:1vh;
    min-height: 2rem;
    text-transform: none; 
}
.sp-btn2{
    background-color:  #F5F5F5;
    border: 1px solid #DDDDDD;
    padding-inline: 3vh;
    padding-block: 1vh;
    font-size: 15px;
    border-radius: 1vh;
    color: #433529;
    margin:1vh;
}

@media only screen and (max-width: 950px) {
.sp-primary-contant-cont{
    padding-right: 0rem;
    width: 100%;
}
}