.cardContainer {
  width: 320px;
  padding-top: 0px;
  padding-bottom: 20px;
  display: flex;
  border-radius: 7px;
  border-color: rgba(204, 204, 204, 1);
  border-width: 0.5px;
  border-style: solid;
  flex-direction: column;
  padding-left: 20px;
  margin-right: 40px;
  margin-top: 43px;
}

.cardTitle {
  font-size: 16px;
  color: rgba(102, 102, 102, 1);
  font-weight: 700;
}

.dateText {
  font-size: 14px;
  color: rgba(102, 102, 102, 1);
  margin-top: -5px;
  margin-bottom: 25px;
}

.cardButton {
  background-color: rgba(70, 125, 247, 1);
  width: 120px;
  height: 30px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}
.mysercard{
  background-color: #F1F1F1;

  height: 140px;
  padding: 1vh;
  margin: 1vh;
  border-radius: 5px;
}

.myserbtn{
  background-color: #D9D9D9;
  padding: 0.5vh;
  border-radius: 5px;
}
