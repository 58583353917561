.mini-sidebar-wrap{
    height: 100svh;
    background-color: #E8E8E8;
    color: inherit;
    min-width: 4em;
    width: fit-content;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}
.icons-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  gap: 1rem;
}
.light-mode {
  background-color: #fff;
  color: #000;
}

/* Dark mode styles */
.dark-mode {
  background-color: #333;
  color: #fff;
}
.mb-25{
  margin-bottom: 25%;
}

.mini-sidebar-wrap2{
    // height: 50%;
    width: 100%;
  // @include customFlex(100%, center, center, column);
}
.mini-sidebar-wrap3{
    width: 100%;

  @include customFlex(100%, flex-end, flex-end, column);
}
.mini-sidebar-wrap4{
    width: 100%;

  @include customFlex(100%, center, center, column);
}
.mini-logos-wrap{
  min-height: 2rem;
  cursor: pointer;
  @include customFlex(100%, center, center);
}
