.blue-block-container {
  height: 25vh;
  width: 100%;
}
.blue-block1 {
  width: 100%;
  background-color: #1e68f9;
  height: 100%;
  border-radius: 7px;
}
.blue-block2 {
  width: 24%;
  background-color: #1e68f9;
  border-radius: 7px;
  height: 100%;
}

.sub-block-wrap {
  padding-block: 2vh;
  @include customFlex(100%, space-between, center, row);
}
.sub-block1 {
  border-radius: 7px;
  margin: 1vh;
  @include customFlex(49%, space-between, center, row);
}
.sub-block-blue {
  flex-wrap: wrap;
  justify-content: space-between;
  @include custom-box(#f6f8fd, #8ea7ff);
  padding: 1vh;
}
.sub-block-iblue {
  font-size: 12px;
  color: #8ea7ff;
}
.sub-block-lorange {
  flex-wrap: wrap;
  justify-content: space-between;
  @include custom-box(#fff0e4, #ffba88);
  padding: 1vh;
}

.sub-block-green {
  flex-wrap: wrap;
  justify-content: space-between;
  @include custom-box(#eeffef, #00c308);
  padding: 1vh;
}

.sub-block-orange {
  flex-wrap: wrap;
  justify-content: space-between;
  @include custom-box(#fff7f4, #ff9472);
  padding: 1vh;
}

.dashboard-grids {
  height: 75%;
  width: 32.3%;
  padding-top: 2rem;
}

.sub-block2 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #c5bdff;
  border-radius: 5px;
  overflow: auto;
}
.sub-block2-top {
  border-bottom: 1px solid #c5bdff;
  @include customFlex(100%, space-between, center, row);
}
.gap-1 {
  gap: 1rem;
}
.h-100vh{
  height: 100svh;
}

.dashboard-client-card{
  width: 50%;
}

.recent-services-card{
  width: 48%;
}

@media only screen and (max-width: 950px) {
  .dashboard-client-card{
    width: 100%;
  }
  
  .recent-services-card{
    margin-top: 1rem;
    width: 100%;
  }
}
.recent-services-title{
  font-size: 24px;
  font-weight: 500;
  color: #33385B;
}

.recent-services-data-card {
  background-color: white;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  gap: .5rem
}

.recent-services-data-card-title{
  font-size: 1em;
  font-weight: 600;
  color: #1e1e1e;
}

.recent-services-data-card-sub-title{
  font-size: .8em;
  font-weight: 600;
  color: #1e1e1e;
}

.need-help-card{
  background-color: transparent;
  width: 100%;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
}

.MuiButton-contained.verify-otp-btn-dashboard{
  margin: auto;
  color: #627284;
  background-color: #eeeeee;
  text-transform: none;
  padding: 1% 3%;
  align-self: flex-start;
  font-weight: 500;
  font-size: 14px;
}

.MuiButton-contained.verify-otp-btn-dashboard:hover{
  background-color: #eeeeeeaf;
}
