



.ServicesContainer{
  width: 100%;
}

.ServicesContainer:hover{
   
}


.servicesName{
    font-size: 18px;
    color: #F1F1F1;
    font-weight: 700;
    overflow-wrap: anywhere;
    margin-left: 10px;
    
}

.servicesContainer {
  width: 100%;
  height: 100vh;  
  justify-content: center;
}

.serviceItem {
  justify-content: left;
  align-items: center;
  flex-direction: column;
  padding: 8px;
  margin-bottom: 8px;
  width: 100%;
  cursor: pointer;
  color: rgba(84, 89, 105, 1);
}

.servicesName:hover {
  font-weight: 700;
  transition: 0.5s;
 
}

.servicesName {
  overflow-wrap: anywhere;
  margin-left: 1vh ;
  width: 80%;
  font-size: 18px;
  color: rgba(84, 89, 105, 1);
  font-weight: 500;
}

@media screen and (max-width: 800px) {
  .sideBarContainer {
    padding-left: 10%;
  }

  .ServicesContainer {
    font-size: 10px;
    padding-left: 0px;
    width: 100%;

  }

  .servicesName {
    font-size: 10px;
  }
}
