@import "../abstracts/mixins.scss";

.legaldoc_card_base {
  @include customFlex(160px);
  height: 250px;
  margin-right: 46px;
  margin-top: 40px;
  border-radius: 5px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  background-color: rgba(210, 209, 209, 0.437);
}

.legal_doc_cardimg {
  @include customFlex();
  height: 200px;
  background-color: rgba(204, 204, 204, 1);
  margin-bottom: 10px;
  background-size: contain;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.legaldoc_onhover_downloadbutton {
  @include customFlex();
  height: 200px;
  background-color: rgba(255, 255, 255, 0.437);
  opacity: 0;
  transition: 0.5s;
}

.legaldoc_onhover_downloadbutton:hover {
  opacity: 1;
  display: flex;
}

.legaldoc_detailsec {
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #808080;
  padding-left: 5px;
  padding-right: 5px;
}
