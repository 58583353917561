.sm-main-container {
  height: 100vh;
  border: 1px solid #d1d1d1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  min-width: 1000px;
}
.category-container {
  width: 25% !important;
  border-right: 1px solid #ddd;
  height: 100vh;
}
.service-container {
  width: 20%;
  border-right: 1px solid #ddd;
  height: 100vh;
}
.service-details-container {
  width: 75% !important;
  // border-right: 1px solid #ddd;
  overflow: auto;
  height: 100vh;
}
.category-title {
  font-size: 18px;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  color: #090E24;
}

.border-bottom-gray {
  border-bottom: 1px solid #ddd;
}
.cursor-pointer {
  cursor: pointer;
}
.category-hr {
  height: 1px;
}
.p-10 {
  padding: 10px;
}
.pl-10 {
  padding-left: 10px;
}
.pr-10 {
  padding-right: 10px;
}
.pt-10 {
  padding-top: 10px;
}

.table-grid {
  border: 1px solid #d1d1d1;
  border-bottom: none;
}

.tg-c1 {
  height: 10vh;
}

.tg-c2 {
  height: 70vh;
}

.tg-1 {
  border-bottom: 1px solid #d1d1d1;
}

.tg-1-1 {
  width: 20%;
  border-right: 1px solid #d1d1d1;
  padding-block: 2vh;
  height: 100%;
}
.tg-c1-1 {
  width: 20%;
  border-right: 1px solid #d1d1d1;
  padding: 2vh;
  height: 100%;
}

.tg-1-2 {
  width: 20%;
  height: 100%;
  border-right: 1px solid #d1d1d1;
  padding: 2vh;
}
.tg-c1-2 {
  width: 20%;
  height: 100%;
  border-right: 1px solid #d1d1d1;
  padding-block: 2vh;
}

.tg-c3-1 {
  justify-content: center;
  align-items: flex-end;
  height: 60%;
}

.tg-12 {
  justify-content: center;
  align-items: center;
  height: 60%;
  display: flex;
}
.tg-b-grid {
  justify-content: center;
  align-items: flex-end;
}
.tg-b {
  background-color: #f1f1f1;
}

.tg-align {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tg-13 {
  width: 60%;
  padding: 2vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.MuiButton-contained.tg-span-b {
  background-color: #ff5e48;
  color: white;
  padding-inline: 2vh;
  padding-block: 0.2rem;
  border-radius: 1vh;
  text-transform: none;
}
.MuiButton-contained.tg-span-b:hover {
  background-color: #ee7070;
}
.MuiButton-contained.tg-span-c {
  padding-inline: 2vh;
  padding-block: 0.2rem;
  border: 1px solid #cacaca;
  border-radius: 1vh;
  color: #303030;
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter";
  background-color: transparent;
  text-transform: none;
}
.MuiButton-contained.tg-span-c:hover {
  background-color: #e6e1e1;
}
.tg-11 {
  padding-block: 1.5vh;
  padding-inline: 2vh;
}
.h-25px {
  height: 25px;
}
.typo-blue {
  background-color: #1e68f9;
  color: white;
  text-align: center;
  border-radius: 2vh;
  font-size: 14px;
  padding-block: 0.2vh;
}

.m1 {
  margin: 1vh;
}

.MuiButton-contained.tg-button1,
.tg-button2 {
  text-decoration: none;
  border-radius: 5px;
  text-transform: none;
}
.MuiButton-contained.tg-button1 {
  color: white;
  border: 0.1px solid transparent;
}

.MuiButton-contained.tg-button2 {
  background: transparent;
  color: #121212;
  border: 0.1px solid #121212;
  text-transform: none;
}
.MuiButton-contained.tg-button2:hover {
  background: white;
}

.tg-g {
  width: 50%;
  height: 100%;
}
.tg-g1 {
  width: 100%;
  height: 100%;
}

.tg-60 {
  width: 60%;
  height: 100%;
}
.tg-70 {
  width: 70%;
  height: 100%;
}
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border-radius: 18px;
}
.w-25 {
  width: 25%;
}
.w-50 {
  width: 50%;
}
.minw-25 {
  min-width: 25%;
}
.minw-50 {
  min-width: 50%;
}
.w-60 {
  width: 60%;
}
.w-75 {
  width: 75%;
}
.w-100 {
  width: 100%;
}
.br-18 {
  border-radius: 18px;
}

.tg-40 {
  width: 40%;
}

.sm-grid-1,
.sm-inp-1 {
  padding: 1vh;
  margin: 1vh 0;
  border-radius: 5px;
  border: 1px solid #d1d1d1;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}
.sm-inp-1 {
  height: 140px;
  inline-size: 95%;
  width: 100%;
}
.sm-font {
  font-size: 14px;
  font-weight: 400;
}
.grid-tg-banner {
  background-color: #f6fbfe;
  height: 20vh;
  border: 2px dashed #e6e6e6;
  border-radius: 0.5vh;
  margin-top: 0.5rem;
}

.grid-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 2vh;
}

.service-select .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: #ffffff;
}
.image-preview {
  position: relative;
  width: 100%;
  height: 100%;
}

.change-icon-menu {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
