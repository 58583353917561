@import "../abstracts/mixins.scss";

.paymentOrderBlock_Container {
  @include customFlex(100%, space-between, center, row);
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 0.5px #b3b3b3 solid;
  padding-left: 40px;
}

.paymentOrderBlock_text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  width: 12%;
  color: rgba(116, 126, 143, 1);
}

.paymentOrderBlock_downloadLogo {
  font-size: 30px;
  color: rgba(116, 126, 143, 1);
  @include customFlex(12%, center, center, null);
}

.paymentOrderBlock_downloadLogo:hover {
  color: rgb(30, 121, 239);
  transition: 0.5s;
}

.paymentOrderBlock_text_1 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  width: 20%;
  color: rgba(116, 126, 143, 1);
}
