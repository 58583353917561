@import "../abstracts/mixins.scss";


.signInMainContainer{
    @include customFlex(55%,  flex-end, center, row);
    border-width: 1px;
    border-color: #E2E0E4;
    border-style: solid;
    padding-top: 10%;
    height: 100vh;
    align-items: flex-start;
}

.SignInmain {
  @include customFlex(100%, center, center, null);
  padding-top: 0;
}

.SignInInputMainCopntainer {
  @include customFlex(100%, center, center, row);
}

.signInContainer {
  @include customFlex(456px, center, center, column);
  background-color: white;
  box-shadow: 5px 5px 15px 10px #e2e0e4;
  padding-bottom: 40px;
  padding-top: 20px;
  border-radius: 20px;
}

.title_register {
  @include customFlex(340px, center);

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  color: #3b4157;
  padding-bottom: 0px;
  margin-bottom: -20px;
}

.inputContainer {
  @include customFlex(340px, center, flex-start, column);

  padding-bottom: 0px;
  margin-top: 10px;
}

.inputlevel {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #4e556a;
}

.input {
  width: 100%;
  border-style: solid;
  border-color: rgba(224, 224, 224, 1);
  border-radius: 5px;
  height: 40px;
  // padding-inline: 10;
  border-width: 3px;
  font-size: 20px;
  margin-top: -10px;
}

.tcContainer {
  @include customFlex(78%, center, center, row);
  margin-top: 20px;
  margin-bottom: 40px;
}

.tctext1 {
  color: rgba(64, 62, 76, 1);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  width: 100%;
}

.tctext2 {
  cursor: pointer;
  margin-left: 2px;
  margin-right: 3px;
  color: rgba(58, 96, 232, 1);
}

.createAccountButton {
  height: 44px;
  background-color: #3a60e8;
  @include customFlex(360px, center, center, column);
  border-radius: 7px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  margin-bottom: 25px;
}

.createAccountButtonText {
  font-size: 20px;
  color: #ffffff;
  font-weight: 500;
}

.haveaAccountContainer {
  @include customFlex(420px, center, center, row);
}

.haveaAccountText {
  color: rgba(64, 62, 76, 1);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
}

.gotoLoginButton {
  color: rgba(58, 96, 232, 1);
  cursor: pointer;
  margin-left: 2px;
  margin-right: 3px;
}
