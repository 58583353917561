@import "../abstracts/mixins.scss";

.view-service-details-container {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #d7d7d7;
  display: flex;
  min-height: 25vh;
  overflow: scroll;
}

.sd-text-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8vh;
  width: fit-content;
  font-weight: 700;
  font-size: 16px;
  color: #9297B4;
  cursor: pointer;
  margin-inline: 3%;
}
.sd-text-wrap-active {
  background-color: #E6EEFA;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  color: #3759DC;
  padding-inline: 3%;
}
.wrap-sd {
  width: 90%;
  padding: 1.5vh;
  min-height: 15rem;
  max-height: 30rem;
}

.wrap-sd1 {
  display: flex;
  flex-direction: column;
}

.request-call-back-btn {
  border: 1px solid #A8A8A8;
  background: none;
  background-color: #fff;
  color: #000;
  margin: 2vh;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  font-weight: 600;
  font-size: 14px;
}

.btn-service-details {
  border: none;
  background: none;
  background-color: #3b82f6;
  color: white;
  border-radius: 5px;
  display: flex;
  margin: 1vh 2vh;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  font-weight: 600;
  font-size: 18px;
}

.btn-service-details:disabled {
  background-color: #a7a9ac;
}

.ser_det_typeSelectorButt {
  @include customFlex(140px, center, center, null);
  height: 30px;
  border: 0.5px solid #b3b3b3;
  border-radius: 4px;
  margin-top: 25px;
  font-size: 16px;
  color: #808080;
  cursor: pointer;
}

.ser_det_Detail {
  @include customFlex(0, flex-start, flex-start, null);
  padding-left: 5%;
  padding-top: 50px;
  color: #808080;
}

.ser-btn1 {
  padding: 1vh;
  background-color: #f6f8fd;
  border: 2px solid #8ea7ff;
  border-radius: 5px;
  margin: 2vh;
}

.ser-btn2 {
  padding: 1vh;
  background-color: #fff0e4;
  border: 2px solid #ffba88;
  border-radius: 5px;
  margin: 2vh;
}

.ser-btn3 {
  padding: 1vh;
  background-color: #f6f8fd;
  border: 0.5px solid #c0c0c0;
  border-radius: 5px;
  margin: 1vh;
}
.sd-text-wrap1.active,
.sd-text-wrap.active {
  background-color: #f0f0f0; /* Highlight color */
}
