.wrap-settings-page {
  padding: 0 2rem 4rem 2rem;
  width: 80%;
}
.w-5 {
  width: 5%;
}
.w-90 {
  width: 90%;
}
.main-content {
  width: 95%;
  margin-left: 5%;
  overflow-x: hidden;
  overflow-y: auto;
}

.main-right-content {
  width: calc(100% - 4em);
}

.main-right-content-container {
  overflow: auto;
  scrollbar-width: none;
}

.wrap-settings-splitA1 {
  width: fit-content;
}
.mb-50 {
  margin-bottom: 25%;
}

.wrap-settings-splitB1 {
  padding: 0rem 2rem 2rem 2rem;
  scrollbar-width: none;
}

.sp-typo{
  margin-bottom: 1rem;
}

.Settings-search {
  height: 64px;
  background-color: #f6f8fd;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  @include customFlex(100%, space-between, center, null);
}
.search-center {
  justify-content: space-between;
  border: 0.5px solid #dad4d4;
  border-radius: 20px;
  width: 420px;
  height: 60%;
  background-color: #fff;
}

.search-input {
  border: 0px solid transparent;
  border-radius: 18px;
  padding: 0.5rem;
  width: 100%;
  height: 36px;
  box-sizing: border-box;
}
.search-input:focus {
  outline: none;
}

.search-bar-right-vertical-line {
  width: 1px;
  min-height: 45px;
  background-color: #8D8D8D;
  margin: 10px;
}

.user-profile-icon{
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #1E68F9;
  color: #fff;
  font-size: 14px;
  font-weight: bold
}
.divider {
  margin-top: 10px;
  border: none;
  border-top: 1px solid #ccc;
  width: 95%;
}

.sidebarContainer {
  width: max-content;
  height: 100vh;
}
.settingsSideBarNames{
  font-size: 14px;
  color: #000000;
  font-weight: 600;
}
.settingsSideBarNamesActive{
  border: 1px solid #ABABAB;
  border-radius: 5px;
}
.settings-sidebar-item > img{
  width: 16px;
  height: 16px;
}

.settings-subtitle{
  font-size: 20px;
  font-weight: 700;
  color: #0D1117;
}

.h-85{
  height: 85vh
}

@media only screen and (max-width: 650px) {
  .search-center {
    width: 30%
  }
}

@media screen and (min-width: 300px) and (max-width: 1650px) {
  .wrap-settings-page {
    width: 100%;
  }
}

@media only screen and (min-width: 2000px) {
  .wrap-settings-page {
    width: 70%;
    max-width: 1800px;
    margin-inline: auto;
  }
}

@media only screen and (min-width: 2560px) {
  .wrap-settings-page {
    max-width: 1800px;
    margin-inline: auto;
  }
}