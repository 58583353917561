.sm-wrap-2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 2rem
}

.sm-w {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

}

.sm-w-w-1{
  display: flex;
  gap: 1rem;
  align-items: center;
}
.sm-w-b-t {
  background-color: #F6F8FD;
  padding: .25% 1%;
  border-radius: 5px;
  margin: 0.5rem;
  width: fit-content;
}

.sm-w-b-t:hover {
  background-color: #e6e7ebee;
}

.sm-w-b-b {
  border: 1px solid #C0C0C0;
}

.sm-category-keys {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
  width: 100%;
  padding: 1% 0;
}

.sm-category-key {
  cursor: pointer;
  background-color: #F6F8FD;
  padding: .25% 1%;
  border-radius: 12px;
  width: fit-content;
  transition: background-color 0.3s;
}

.sm-category-key:hover {
  background-color: #e6e7ebee;
}
.sm-category-key.active {
  border: 1px solid #1E68F9;
}

.sm-w-3{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}
.sm-d-1{
 width: 50%;
 background-color: #1E68F9;
height: 250px;
border-radius: 2vh;
margin: 1vh;


}

.sm-d-2{
  width: 50%;
height: 100%;
border-radius: 2vh;
margin: 1vh;
border: 2px solid #9C9C9C;
  background-color: #F1F1F1;
 
 
 }

 .sm-w-d-2{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-block: 2vh;
 }

 .sm-div-1{
  padding: 1vh;
 
  
 }

 .sm-t-div{
  padding-inline: 5vh;
  border-radius: 5px;
border: 0.5px solid #9C9C9C;
 }

 .sm-t-div-1{
  border-radius: 5px;
border: 1px dashed #9C9C9C;
background: #F1F1F1;
padding-inline: 2vh;

 }
.w-10{
  width: 10rem;
}
 .sm-b{
  border:none;
  background: none;
  padding-inline: 4vh;
  border-radius: 4px;
  color:white;
  font-weight: 400;
  font-size: 14px;
 }

 .sm-b-b{
  background-color: #1E68F9;
 }

 .sm-b-r{
  background-color: #FF5E48;
 }

 .sm-f{
  display:flex;
  justify-content: flex-end;
  align-items:center;
 }

 .sm-f-1{
  padding: 2vh;
 }
 .sm-search{
  height: 1.8rem;
  border-radius: 4px;
  padding: .5rem;
}
 .sm-f-2{
  border-radius: var(--borderRadius, 4px);

  border: 1px solid #2A2A2A;
  
  background: #FFF;
  
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);

padding-inline: 1vh;

 }

 .sm-w-4{
  width: 100%;
  height: 200px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
 }
 
 .highlight-content-container{
    background-color: #E5F1FB ;
    border-radius: 5px;
 }