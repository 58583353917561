.spn-w1{
    height: 80%;
    width: 100%;
    @include customFlex(100%, center, center, column);

}
.spn-wrap11{
    width: 100%;
    @include customFlex(100%, column);
    border-top: 0.5px solid #D7D9DD;
    padding-top: 3vh;
}
.spn-wrap1{
    @include customFlex(90%, flex-start, flex-start, row   );
    

}
.spn-wrap2{
    width:20%;

}

.spn-wrap4{
    width: 60%;
    height: 100%;

}
.spn-w2{
    padding-block: 1vh;
}
.textWrap{
    padding-block: 0.5vh;
   
}
.spn-notifWrap{
  
    padding-inline: 2vh;
}
.spn-key-title-text{
    font-size: .9em;
    font-weight: 600;
    color: #20252B
}
.spn-key-subtitle-text{
    font-size: .75em;
    font-weight: 500;
    color: #6C6F78
}