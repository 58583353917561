.spl-wrap {
  @include customFlex(100%, center, center, column);
  width: 100%;
  height: 100%;
}
.spl-wrap11 {
  @include customFlex(100%, column);
  width: 100%;
  border-top: 0.5px solid #d7d9dd;
}
.spl-wrap1 {
  width: 100%;
  min-height: 30%;
}
.spl-w1 {
  padding-block: 1.5vh;
}
.spl-wrap3 {
  width: 90%;
  height: 30%;
}

.textWrap {
  padding-block: 0.5vh;
}
.textWrap2 {
  @include customFlex(flex-start, row);
  padding-block: 1vh;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
}
.spl-input {
  padding: 0.5vh;
  margin-inline: 1rem;
  border-radius: 5px;
  min-height: 2em;
  min-width: 15em;
  border: 1px solid #D0D0D0;
}
.spl-input:disabled {
  background-color: #f0f0f0;
  color: #888;
  cursor: not-allowed;
}

.spl-verified {
  color: #35a500;
  padding: .5vh 2vh;
  background-color: #e9e9e9;
  border-radius: 20px;
  margin-inline: 1vh;
  justify-content: center;
}
.spl-icon {
  margin-right: 4px;
}
.spl-typo {
  display: flex;
  align-items: center;
  width: 25%;
}
.spl-switch {
  margin-left: 8px;
  display: flex;
  align-items: center;
}
.MuiButton-contained.reset-btn {
  display: none;
  height: 2rem;
  text-transform: none;
}
