.sr-g-1 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-block: 2vh;
  height: 100vh;
  border: none;
  border-left: 1px solid #d1d1d1;
  border-radius: 5px;
}

.rg-col {
  min-width: 30%;
  height: 100%;
  overflow: auto;
  border: 1px solid #d1d1d1;
  border-left: none;
}

.sr-l-1 {
  border-top-left-radius: 1vh;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0.5rem 0;
  min-height: 2rem;
}

.sr-l-2 {
  width: 100%;
  // min-height: 80vh;
  display: flex;
  // border-inline: 1px solid #d1d1d1;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: scroll;
}
.sr-l-2-t {
  width: 100%;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.sr-l-2-t:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}
.sr-al {
  display: flex;
  align-items: flex-end;
}
.word-break {
  word-break: break-word;
}
.w-70 {
  width: 70%;
}

.sr-del {
  display: flex;
  background-color: #ffdada;
  color: #ff3636;
  padding: 0.2vh;
  border-radius: 6px;
}
.sr-edit {
  display: flex;
  background-color: #d7e2ff;
  color: #1e68f9;
  padding: 0.2vh;
  border-radius: 6px;
  margin-left: 0.5rem;
}

.sr-l-3 {
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  border-right: 1px solid #d1d1d1;
  border-bottom-left-radius: 1vh;
}

.sr-col-r {
  background-color: #fadcde;
  color: #86181c;
  font-weight: 600;
}
