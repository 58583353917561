.sidepop-profile{
    height: 7vh;
    background-color: #F6F8FD;
}
.sidebar-width-wrap1{
    // width: 85%;
}
.sidebar-width-wrap2{
    width: 15%;

}

.sidepop-head1{
    padding: 1vh;
    font-weight: 600;
}
.border-l{
    border-left: 1px solid #E6E6E6;
}

.sidepop-div-1{
width: 100%;

}
.sidepop-div-1 p{
    margin: 0px;
    padding: 0px;
    padding-inline: 2vh;
    font-size: 14px;
}
.sidepop-div{
display: flex;
justify-content: center;
align-items: center;
padding: 1vh;
}